<template>
  <div class="claimelly" :class='[(mode) ? "light" : "dark"]'
  >
  
    <div class="position-ralative">
      <p class="position-absolute c-text" v-if='!expired'
      :class='[(mode) ? "text-whites" : "text-darks"]'
      style='    bottom: 11px;
    font-size: 20px;'
      >
       Claim Period Expiring In: 
       <span class='orbit'>
       {{days}}d {{hours}}h {{minutes}}m {{seconds}}s

       </span>
      </p>
      <p class="position-absolute"
      :class='[(mode) ? "text-whites" : "text-darks"]'
       v-else
      style='    bottom: 11px;
    font-size: 20px;'
      >
       Claim Period Has Been Expired
      </p>
      <button class="position-absolute btn btn-primary-outline btn-mode" @click='mode = !mode'>
        <img v-if='mode' src="/img/sun-color.png" width="103" height="40" alt="light">
        <img v-else src="/img/moon-black.png" width="103" height="40" alt="light">
      </button>
    </div>
                <div class="position-relative" >
                    <img src="/img/icon.png" style='width:300px;height:42px;' class='d-none d-lg-block icon link' @click='goTo' alt="icon">
                    <img src="/img/icon.png" style='width:200px;height:50px;' class='d-lg-none icon-mob link' @click='goTo' alt="icon">
                    <div class="hr float-right d-none d-lg-block"></div>
                    <span class="btn-pri position-absolute d-none d-lg-block" @click='connect'>
                    {{selectedAccount?(selectedAccount.substr(0,7)+"....."+selectedAccount.substr(-8)):"Connect Wallet"}}
                    </span>
                    <span class="btn-pri-mob position-absolute d-lg-none" @click='connect'>
                    {{selectedAccount?(selectedAccount.substr(0,7)+"....."+selectedAccount.substr(-8)):"Connect Wallet"}}
                    </span>
                    
                </div>
                <div v-if='!isAdmin && selectedAccount'>
                      <h1 class="text-center txBgPri" style='margin-top:  5rem;' v-if='loading'>Loading</h1>
                      <h1 class="text-center txBgPri" style='margin-top:  5rem;' v-else>Claim</h1>
                    <div class="row justify-content-center">
                      <div class="col-12 col-lg-10 inp-border border-0 py-3 px-4"
                      :class='[(mode) ? "light" : "dark"]'
                      >
                          <h2 :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]' class=" border-0 mb-0 p-0 float-left " id="">
                            {{claimData.claims[selectedAccount] ? parseInt(claimData.claims[selectedAccount]['amount'],16)/1000000000000000000 : "00.00"}}
                          </h2>
                          <h2 :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]' class=" border-0 mb-0 p-0 float-right text-right" style='width:155px;' id="">{{symbol}}</h2>
                      </div>
                      <div class="col-12 col-lg-10" v-if='claimData.claims[selectedAccount] !== undefined'>
                        <h2 class="my-5" :class='[(mode) ? "text-whites" : "text-darks"]' >Available to Claim</h2>
                      </div>
                      <div class="col-12 col-lg-10" v-else>
                        <h2 class="my-5" :class='[(mode) ? "text-whites" : "text-darks"]' > Not Available to Claim</h2>
                      </div>

                      <div class="col-12 col-lg-10" v-if='claimData.claims[selectedAccount] !== undefined'>
                        <span class="btn-pri d-block mx-auto"
                        v-if='!expired'
                        @click='claim(parseInt(claimData.claims[selectedAccount]["amount"],16)/1000000000000000000)'>{{isClaimDone ? "Already Claimed" : "Claim Now"}}
                        </span>
                      </div>
                      <div class="col-12 col-lg-10" v-else>
                       
                      </div>
                    </div>

                  </div>
                <div v-else-if='isAdmin && selectedAccount' style='margin-top:5rem;'>

                  <div v-if='selectedAccount == admin || selectedAccount == owner'>
                    <div class="d-none d-sm-block">
                    <p class='text-center det_spec mb-0'>Contract: <b>{{cAddress}} 
                      <span class='btn btn-link px-0' @click='copy(cAddress)'>&#x2398;</span>
                       </b></p>
                    <p class='text-center det_spec mb-0'>Admin: <b>{{admin}} 
                      <span class='btn btn-link px-0' @click='copy(admin)'>&#x2398;</span>
                       </b></p>
                    <p class='text-center det_spec mb-0'>Owner: <b>{{owner}} 
                      <span class='btn btn-link px-0' @click='copy(owner)'>&#x2398;</span>
                       </b></p>    
                    </div>
                    <div class="d-sm-none">
                      <p class='text-center det_spec-mob mb-0'>Contract: <b>{{FcAddress}} 
                        <span class='btn btn-link px-0' @click='copy(cAddress)'>&#x2398;</span>
                         </b></p>
                      <p class='text-center det_spec-mob mb-0'>Admin: <b>{{Fadmin}} 
                        <span class='btn btn-link px-0' @click='copy(admin)'>&#x2398;</span>
                         </b></p>
                      <p class='text-center det_spec-mob mb-0'>Owner: <b>{{Fowner}} 
                        <span class='btn btn-link px-0' @click='copy(owner)'>&#x2398;</span>
                         </b></p>    
                    </div>
                </div> 
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3"
                    v-if='selectedAccount == admin'
                    >

                      <div class="input-group shadow-cc">
                        <div class="input-group-prepend">
                          <span class="input-group-text shadow-c d-inline-block" 
                          :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                           style='width:135px;border-radius:22px;' id="">Select Token:</span>
                        </div>
                        <select name="" id="" v-model='wToken' class='form-control inp-border border-0 ml-1'
                        :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                        >
                          <option :value="null" disabled> Please Select a Token </option>
                          <option v-for='token in availableTokens' v-if='token.currency.symbol !== "BNB"' :key='token.currency.address' :value="token.currency.address">
                            <span class="float-left"> {{token.currency.symbol}} </span>
                            <span class="float right">  (<b>{{token.value}}</b>) </span>
                            
                            
                            </option>
                        </select>
                        <!-- <input type="text" placeholder='Address' class="form-control inp-plain" v-model='wToken'> -->
                      </div>
                      

                    </div>
                     <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3"
                     v-if='selectedAccount == admin'
                     >

                      <div class="input-group shadow-cc">
                        <div class="input-group-prepend">
                          <span class="input-group-text shadow-c d-inline-block"
                          :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                           style='width:135px;border-radius:22px;' id="">Amount:</span>
                        </div>
                        <input type="number" placeholder='0' 
                        :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                         class="form-control inp-plain" v-model='wAmount'>
                        <div class="input-group-append"
                        :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                        >
                          <span v-if='wAmount < 1 && !validateWToken'
                          class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>
                          <span v-else @click='withdraw' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>

                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-12 col-lg-10 mb-3">
                      <p class="m-0 p-0 text-danger float-left" v-if='validateWToken'><i>Please add a valid address</i></p>
                    </div> -->

                    <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3"
                    v-if='selectedAccount == admin || selectedAccount == owner'
                    >

                      <div class="input-group shadow-cc">
                        <div class="input-group-prepend">
                          <span class="input-group-text shadow-c d-inline-block"
                          :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                           style='width:135px;border-radius:22px;' id="">Fund Amount:</span>
                        </div>
                        <input type="number" placeholder='0' 
                        :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                         class="form-control inp-plain" v-model='fAmount'>
                        <div class="input-group-append">
                          <span v-if='fAmount < 1'
                          class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Fund Contract</span>
                          <span v-else @click='fund' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Fund Contract</span>
                        </div>
                      </div>
                    
                    </div>

                    <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3 d-none d-lg-block"
                    v-if='selectedAccount == owner'
                    >

                      <div class="input-group shadow-cc">
                        <div class="input-group-prepend">
                          <span class="input-group-text shadow-c d-inline-block"
                          :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                           style='width:135px;border-radius:22px;' id="">Change Admin:</span>
                        </div>
                        <input type="text" placeholder='Enter a valid Wallet Address' 
                        :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                         class="form-control inp-plain" v-model='changeAdmin'>
                        <div class="input-group-append">
                          <span v-if='validatedChangeAdmin'
                          class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Update</span>
                          <span v-else @click='updateAdmin' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Update</span>
                        </div>
                      </div>
                      
                    
                    </div>
                    <div class="col-12 col-lg-10  py-0 px-0 mt-3 d-lg-none"
                    v-if='selectedAccount == owner'
                    >
                    <div class="form-group"
                    :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                    >
                        <label for=""> Change Admin </label>
                        <input type="text" name="" id=""
                        :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                         placeholder='Enter a valid Wallet Address' v-model='changeAdmin' class="form-control">
                    </div>
                    <div class="form-group">
                          <span v-if='validatedChangeAdmin'
                          class="input-group-text shadow-c btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Update</span>
                          <span v-else @click='updateAdmin' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Update</span>
                        
                        </div>
                    </div>


                    <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3"
                    v-if='selectedAccount == admin'
                    >

                      <div class="input-group shadow-cc">
                        <div class="input-group-prepend">
                          <span class="input-group-text shadow-c d-inline-block"
                          :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                           style='width:135px;border-radius:22px;' id="">Withdraw {{symbol}}:</span>
                        </div>
                        <input type="number"
                        :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                         :placeholder='"withdraw all "+claimAccountBalance+" "+symbol' disabled  class="inp-dis form-control inp-plain" v-model='wToken'>
                        <div class="input-group-append">
                            <span v-if='!claimAccountBalance'
                          class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>
                          <span v-else @click='withdrawAll' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>
                        
                          <!-- <span @click="withdrawAll" class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">withdraw</span> -->
                        </div>
                      </div>
                    
                    </div>
                    <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3"
                   v-if='selectedAccount == admin'
                   >
                   <div class="input-group shadow-cc">
                        <div class="input-group-prepend">
                          <span class="input-group-text shadow-c d-inline-block"
                          :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                           style='width:135px;border-radius:22px;' id="">Withdraw BNB:</span>
                        </div>
                        <input type="number"
                        :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                         :placeholder='"withdraw all "+claimAccountNativeBalance' disabled  class="inp-dis form-control inp-plain" v-model='wToken'>
                        <div class="input-group-append">
                          <span v-if='!claimAccountNativeBalance'
                          class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>
                          <span v-else @click='withdrawNative' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Withdraw</span>
                        
                          <!-- <span @click="withdrawNative" class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">withdraw</span> -->
                        </div>
                      </div>

                          <!-- <span class="btn-prim-big" style='border-radius:22px;' id="" @click='withdrawNative'>Withdraw</span> -->
                   </div>
                    <div class="col-12 col-lg-10 inp-border border-0 shadow py-0 px-0 mt-3 d-none d-lg-block"
                    v-if='selectedAccount == admin || selectedAccount == owner'
                    >

                      <div class="input-group shadow-cc">
                        <div class="input-group-prepend">
                          <span class="input-group-text shadow-c d-inline-block"
                          :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                           style='width:135px;border-radius:22px;' id="">Check Claim:</span>
                        </div>
                        <input type="text" placeholder='Enter a valid Wallet Address'
                        :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                          class="form-control inp-plain" v-model='vCheck'>
                        <div class="input-group-append">
                          <span v-if='validatedWalletCheck'
                          class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Check</span>
                          <span v-else @click="checkValue" class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Check</span>
                        </div>
                      </div>
                  <div v-if='walletCheckResults' v-html='walletCheckResults'></div>
                    
                    </div>
                    <div class="col-12 col-lg-10  py-0 px-0 mt-3 d-lg-none"
                    v-if='selectedAccount == admin || selectedAccount == owner'
                    >
                    <div class="form-group"
                    :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                    >
                        <label for=""> Claim Check </label>
                        <input type="text" name="" id=""
                        :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                         placeholder='Enter a valid Wallet Address' v-model='vCheck' class="form-control">
                    </div>
                    <div class="form-group"
                    :class='[(mode) ? "bg-whites text-whites" : "bg-darks text-darks"]'
                    >
                          <span v-if='validatedWalletCheck'
                          class="input-group-text btn-drk d-inline-block" style='width:135px;border-radius:22px;' id="">Check</span>
                          <span v-else @click='checkValue' class="input-group-text btn-drks d-inline-block" style='width:135px;border-radius:22px;' id="">Check</span>
                        </div>
                    </div>
                  <div v-if='walletCheckResults' v-html='walletCheckResults'></div>

                   


                  </div> 
              </div>

                <div class="position-relative" v-if='selectedAccount'>
                  <button v-if='selectedAccount == admin || selectedAccount == owner' class="btn btn-link position-absolute btn-tab" @click='isAdmin = !isAdmin'> 
                    {{ (isAdmin) ? "User" : "Admin" }}
                    
                    </button>
                </div>

              </div>
</template>

<script>
import Web3 from 'web3';
const web3 = new Web3(Web3.givenProvider);
import detectEthereumProvider from '@metamask/detect-provider';
import {mapState} from 'vuex';
export default {
  computed:{
    ...mapState(['symbol','availableTokens','claimAccountNativeBalance','cAddress','claimable','isClaimDone','accounts','claimData','claimAccountBalance','TokenBalance','owner','admin']),
    validateWToken(){
      let cond = web3.utils.isAddress(this.wToken)
      if (cond) {
        return false;
      } else {
        return true
      }
    },
    getClass(){
      return (this.mode) ? "light" : "dark";
    },
    validatedChangeAdmin(){
      let cond = web3.utils.isAddress(this.changeAdmin)
      if (cond) {
        return false;
      } else {
        return true
      }
    },
    validatedWalletCheck(){
      let cond = web3.utils.isAddress(this.vCheck)
      if (cond) {
        return false;
      } else {
        return true
      }
    },
    FcAddress(){
      return this.cAddress.substr(0,7)+"....."+this.cAddress.substr(-8)
    },
    Fadmin(){
      return this.admin.substr(0,7)+"....."+this.admin.substr(-8)
    },
    Fowner(){
      return this.owner.substr(0,7)+"....."+this.owner.substr(-8)
    },
  },
  data() {
    return {
      loading:false,
      mode:false,
    fundAmount:0,
    walletCheckResults:null,
    vCheck:'',
    isAdmin:false,
      changeAdmin:'',
      selectedAccount:null,
      days:'',
      hours:'',
      web3:null,
      days:'',
      hours:'',
      minutes:'',
      seconds:'',
      countDownDate:new Date("Aug 23, 2022 4:14:25").getTime(),
      distance:null,
      now:null,
      expired:false,
      wAmount:0,
      fAmount:0,
      wAddress:null,
      wToken:null,
      // wToken:"0x542f71A83dd4c11a56a9071d7301D6AdbFf9E346",
    }
  },
  filters: {
  fmAmt: function (amt) {
    if (amt !== undefined ) {
    let amts = amt.replace("0x","");
        amts = (Number(amts) / 1000000000000000000)
        return amts  
    }else{
      return 0.00
    }
    
  }
},
  async mounted() {
    await this.timer();

    // let resp = await fetch('https://api.bscscan.com/api?module=contract&action=getabi&address=0x1A8ABcfDf145379C2443eb7a6e3d127186C867DB&apikey=YU1IGKUWQDN49FP4T3YGV97VDV56HVG7AW')
    // let object = await resp.json()
    // console.log(object)
},
  methods:{
    setDays(value) { this.days = value},
    setHours(value) { this.hours = value},
    setMinutes(value) { this.minutes = value},
    setSeconds(value) { this.seconds = value},
    timer() {
      var x = setInterval(()=>{

      // Get today's date and time
      this.now = new Date().getTime();
        
      // Find the distance between now and the count down date
      this.distance = this.countDownDate - this.now;
        
      // Time calculations for days, hours, minutes and seconds
      this.setDays(Math.floor(this.distance / (1000 * 60 * 60 * 24)));
      this.setHours(Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      this.setMinutes(Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60)));
      this.setSeconds(Math.floor((this.distance % (1000 * 60)) / 1000));
          
      // count down is over 
      if (this.distance < 0) {
        clearInterval(x);
        // alert("EXPIRED");
        this.expired = true;
      }
    }, 1000);
    },
    copy(text){
  var copyText = text

  navigator.clipboard.writeText(copyText);

  alert("Copied the text: " + copyText);
  

    },
    goTo(){
      window.open("https://claimelly.com", "_blank")
    },
    goTos(){
      window.open("https://hbsc-website-test-qelxg.ondigitalocean.app", "_blank")
    },
    runBquery(){
      this.$store.dispatch("Bquery");
    },
    async checkValue(){
      let val = Web3.utils.toChecksumAddress( this.vCheck ) ;
      let result = this.claimData.claims[ val ];
      if (result !== undefined) {
        let dec = await this.$store.dispatch("checkClaimStatus", val)
        let status = (dec) ? "Claimed" : "UnClaimed"
        console.log(result, dec)
        let amt = result.amount
        amt = (Number(amt) / 1000000000000000000)
        let message = "the wallet "+val+" has a Claim of "+amt+" "+this.symbol+"  and claim status is "+status+""
        alert(message)
      }else{
        let message = ' This wallet is not eligible for Claim'
        alert(message)
      }
      console.log(this.claimData.claims)
    },
    async withdrawNative(){
      this.$store.dispatch("withdrawNative",{sender: this.selectedAccount})
    },
    async withdraw() {
      console.log("running withdraw function")
      if(this.wAmount > 0 && this.wToken.length > 5){
        console.log(this.wAmount)
      let dec = await this.$store.dispatch('withdraw', {
        sender: this.selectedAccount,
        amount: this.wAmount,
        token: this.wToken
      })
      if (dec) {
          this.$store.dispatch('Bquery');
        // await this.$store.dispatch('BalanceCheck',  this.selectedAccount) ;
          this.wAmount = 0
          this.wToken = ''
      }
      }else{
        alert('please provide valid value and address')
      }
    },
      async withdrawAll(){
      let admin = this.admin
      let claimAccountBalance = this.claimAccountBalance
      let symbol = this.symbol
      let message = "Are you sure you want to withdraw "+claimAccountBalance+symbol+" to wallet "+admin
      console.log(message, admin, claimAccountBalance, symbol)
      let d = confirm(message)
      if (d) {
        let dec = await this.$store.dispatch("withdraw", {sender: this.selectedAccount, amount:claimAccountBalance})
      if (dec) {
        await this.$store.dispatch('contractBalance');
        await this.$store.dispatch('BalanceCheck',  this.selectedAccount) ;

      }  
      }
      
    },
    async updateAdmin(){
      await this.$store.dispatch("updateAdmin", {wallet:this.changeAdmin, sender: this.selectedAccount})
      this.$store.dispatch('getOwner');
       this.$store.dispatch('getAdmin');
       this.changeAdmin = '' 
    },
   
     onComplete(data) {
      this.selectedAccount = data.metaMaskAddress
      console.log(data)
    },
    async fund() {
      await this.$store.dispatch('fundContract',{amount:this.fAmount})
      this.fAmount = 0
      await this.$store.dispatch("contractBalance");
    },
    async setProvider() {
      const provider = await detectEthereumProvider();
      if (provider) {
        this.web3 = new Web3(provider);
        provider.enable();
        const chainId = await provider.request({
            method: 'eth_chainId'
          })
        provider.enable();

        console.log('chainID', chainId);
        window.ethereum.on('chainChanged', () => {
          this.connect()
        })
        window.ethereum.on('accountsChanged', () => {
          // window.location.reload();
          this.connect()
        })
        
        // 0x38 for mainnet
        // 0x61 for mainnet
        if (chainId !== "0x38") {
          try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x38' }], // chainId must be in hexadecimal numbers
          });  
          } catch (error) {
            console.log("Error in changing chain", error)
            alert("Please Add BSC network in Meta Mask")
          }
          
          return false;
        }
        provider.enable();
        return true
      } else {
        console.log('Please install MetaMask!');
        return false;
      }
    },
    async connect(){
      let dec = await this.setProvider(); 
      console.log('set ptovidder result', dec)
      if (dec) {
        let user = null;
          let addresses = await window.ethereum.request({method: "eth_requestAccounts",});
              if(addresses.length>0){
                await web3.eth.getAccounts((error,result) => {
                  console.log(error, result)
                    if (error || result.length < 1) {
                        console.log(error, 'or no accounts');
                    } else {
                      console.log('setting up user account',result[0])
                        user = result[0]
                    }
                }).then(() => {
                  if(user){
                    console.log('found user', user)
                    this.selectedAccount = user
                    // this.$store.state.owner = user
                    // this.$store.state.admin = user
                    this.$store.dispatch("loadSymbol")
                    this.$store.dispatch('getOwner');
                    this.$store.dispatch('getAdmin');
                    this.$store.dispatch('contractBalance');
                    this.$store.dispatch('Bquery');
                    
                    this.$store.dispatch('BalanceCheck', user) ;
                  }else{
                    console.log(user,'user not found')
                  }
                })
                }
        }  
      
    },
    async claim(value) {
      if(window.ethereum)
      {
        let amt = value
        let dec = await this.$store.dispatch("checkClaimStatus", this.selectedAccount)
        console.log("result of claim status check is", dec)
        if (!dec) {
            let de = await this.$store.dispatch('hasClaimed', {account:this.selectedAccount,value:amt} )
            console.log('transaction result', de)    
        } else {
          alert("Already Claimed")
        }
      
      }else{
        console.log('not found meta mask')
        alert("Install Metamask")
      }
    },
    
   
  }
  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
.orbit{
font-family: 'Orbitron', sans-serif;

}
.btn-mode{
  right: 25px;
    top: 42px;
    padding: 6px;
    border-radius: 0px 33px 0px 11px;
}
.btn-mode:focus {
  outline: none;
  box-shadow: none;
}
.dark{
      background: black;
}
.bg-darks{
      background: transparent;
}
.light, .bg-whites{
      background: #FFF;
}
.text-whites{color:black;}
.text-darks{color:#FFF;}
.dark .shadow-cc{
    border-radius:33px;
    box-shadow: 0px -1px 19px #888888;
}
.dark .shadow-c{
  border: 0px !important;
    box-shadow: 3px 0px 9px 1px #888
}
.dark{
  border: 0px !important;
    box-shadow: 0px -1px 19px #888888;
}
.light{
  border: 0px !important;
    box-shadow: 0px -1px 19px lightslategrey;
}
.dark .input-group-text{
  border:0px !important;
}
</style>